/*
 * @Author: 王子龙
 * @Date: 2022-05-14 19:54:39
 * @LastEditTime: 2023-02-04 09:21:53
 * @LastEditors: wangzilong1997 1321787581@qq.com
 * @Descripttion: 
 */

import Header from '@/component/header/index'
import React, { useEffect, useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table, Space, Tag, Radio, Tabs, Modal, Select} from 'antd';
// import axios from 'axios';
import { axios }from '../../utils/reques'
import qs from 'qs';
import moment from 'moment';

import CustomTable from '../../component/CustomTable';
const { TextArea } = Input;

const ZhangPages: React.FC<any> = (props) => {

  const  [data,setData] = useState([])
  const [tabIndex,setTabIndex] = useState(1)
  const [name,setName] = useState('')
  const [phone,setPhone] = useState('')

//创建ref实例
const [form] = Form.useForm();
const [form2] = Form.useForm();
const [form3] = Form.useForm();

  const [createModal,setCreateModal] = useState(false)
  const [createModal2,setCreateModal2] = useState(false)

  const [addCardModal,setAddCardModal] = useState(false)

  const [lookModalVisible,setLookModalVisible] = useState(false)

  const [clickUserid,setClickUserid] = useState("")

  const [shouldUpdate,setShouldUpdate] =useState(+new Date())

  const [userInfo,setUserInfo] = useState({})

  const [cardList,setCardList] = useState([])
  const [shouldUpdate2,setShouldUpdate2] =useState(+new Date())
  useEffect(() => {
    console.log('请求接口数据')
    if(tabIndex == 1){
      axios.get(`/liyongqi/user-list`)
      .then((res) => {
        console.log('ressss', res)
  
        let data = res.data.data
        setData(data)
  
      })
    }
    if(tabIndex == 2){

      axios.get(`/liyongqi/card-list`,{})
      .then((res) => {
        console.log('ressss', res)
  
        let data = res.data.data
        setCardList(data)
  
      })
    }

  },[tabIndex,shouldUpdate])

  useEffect(() => {
    if(!clickUserid) {
      return  
    }
    console.log('请求接口数据')
    axios.get(`/liyongqi/user-detail`,{params:{id:clickUserid}})
    .then((res) => {
      console.log('ressss', res)

      let data = res.data.data
      setUserInfo(data)
      form2.setFieldsValue(data.baseInfo)
      setLookModalVisible(true)

    })
    axios.get(`/liyongqi/card-list`,{})
    .then((res) => {
      console.log('ressss', res)

      let data = res.data.data
      setCardList(data)

    })
  },[clickUserid,shouldUpdate2])

  useEffect(()=>{
    if(addCardModal){
      form3.setFieldsValue({name:userInfo.baseInfo.name})
      axios.get(`/liyongqi/card-list`)
      .then((res) => {
        console.log('ressss', res)
  
        let data = res.data.data
        setCardList(data)
  
      })

    }

  },[addCardModal])
  
  const { confirm } = Modal;
  const showConfirm = (item,user) => {
    confirm({
      title: '使用',
      icon: <ExclamationCircleOutlined />,
      content: <div><span style={{color:"#1890ff"}}>{user.name + " " + user.phone}</span>使用<span style={{color:"#1890ff"}}>{item.card_name}</span></div>,
      okText:"确定",
      cancelText:"取消",
      
      onOk() {
        console.log('OK');
        axios.post(`/liyongqi/card-use`,qs.stringify({
          cardid:item.cardid,
          userid:user.id
        }))
        .then((res) => {
          setShouldUpdate2(+new Date())
    
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return <>
    {/* <Header /> */}
    <div style={{

      padding:"50px"
    }}>
    <Tabs onChange={(e) => {

      console.log(e)
      setTabIndex(e)
    }}>
      <Tabs.TabPane tab="用户列表" key="1">
        <Button type="primary" onClick={() => {setCreateModal(true)}}>新建用户</Button>
        <Table 
          columns={[
          {
            title: '序号',
            dataIndex: 'index',
            // key: 'index',
            render(value, record, index) {
              return index + 1
            },
          },
          {
            title: '姓名',
            dataIndex: 'name',

          },
          {
            title: '手机',
            dataIndex: 'phone',

          },

          {
            title: '操作',
            render(value, record, index) {
              return <Button onClick={() => {
                setClickUserid(record.id)

              }}>
                查看
              </Button>
            },

          },
        ]}
          dataSource={data}
          onChange={function(pagination, filters){
            return
            console.log('filters',filters)
            let res = []
            if(filters.city.length != 0){
              data.forEach(item => {
                console.log('data.forEach...',filters.city?.indexOf(item.kq),item.kq,filters.city)
                if(filters.city?.indexOf(item.city) != -1){
                  console.log('2data.forEach...',filters.city?.indexOf(item.city),item.kq,filters.city)
                }

                if(filters.city?.indexOf(item.city) != -1){
                  let obj = {
                    text:item.bm,
                    value:item.bm
                  }
                  res.push(obj)
                }

              })
            }
            console.log('kqFilter',res)
            // setKqFilters(
            //   res
            //   )
          }}
        >

        </Table>
        <Modal 
          title={"新建用户"}
          visible={createModal}
          onCancel={() =>{
            setCreateModal(false)
          }}
          onOk={() =>{
            form.validateFields().then(res => {
              
              axios.post(`/liyongqi/user-add`,qs.stringify(res))
              .then((res) => {
                console.log(res)
                if(res.data.code == 200){

                  setShouldUpdate(+new Date())
                  setCreateModal(false)
                  form.setFieldsValue({name:"",phone:""})
                }

              })
            })
          }}
        >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '输入用户名称' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="手机号"
            name="phone"
            rules={[{ required: true, message: '输入手机号' }]}
          >
            <Input />
          </Form.Item>
        </Form>
        </Modal>

        <Modal 
          title={"查看用户"}
          width={1250}
          visible={lookModalVisible}
          onCancel={() =>{
            setLookModalVisible(false)
          }}
          footer={null}
          onOk={() =>{
            form.validateFields().then(res => {
              
              axios.post(`/liyongqi/user-add`,qs.stringify(res))
              .then((res) => {
                console.log(res)
                if(res.data.code == 200){

                  setShouldUpdate(+new Date())
                  setCreateModal(false)
                  form.setFieldsValue({name:"",phone:""})
                }

              })
            })
          }}
        >
        <div>用户基本信息</div>
        <Form

          form={form2}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '输入用户名称' }]}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            label="手机号"
            name="phone"
            rules={[{ required: true, message: '输入手机号' }]}
          >
            <Input disabled/>
          </Form.Item>
        </Form>

        <div>用户拥有卡片         <Button type="primary" onClick={() => {
          setAddCardModal(true)
          
        }}>为用户添加卡片</Button></div>
        <Table 
          columns={[
          {
            title: '序号',
            dataIndex: 'index',
            // key: 'index',
            render(value, record, index) {
              return index + 1
            },
          },
          {
            title: '会员卡id',
            dataIndex: 'cardid',

          },
          {
            title: '卡名称',
            dataIndex: 'card_name',

          },
          {
            title: '使用次数',
            dataIndex: 'card_count',

          },
          {
            title: '金额',
            dataIndex: 'card_money',

          },

          {
            title: '使用次数',
            dataIndex: 'use_time',

          },
          
          {
            title: '剩余次数',
            render(value, record, index) {
              return record.card_count - record.use_time
            },

          },

          {
            title: '操作',
            render(value, record, index) {
              return <Button onClick={() => {
                showConfirm(record,userInfo.baseInfo)
              }}>
                使用
              </Button>
            },

          },
        ]}
          dataSource={userInfo.cardInfo}
          onChange={function(pagination, filters){
            return
            console.log('filters',filters)
            let res = []
            if(filters.city.length != 0){
              data.forEach(item => {
                console.log('data.forEach...',filters.city?.indexOf(item.kq),item.kq,filters.city)
                if(filters.city?.indexOf(item.city) != -1){
                  console.log('2data.forEach...',filters.city?.indexOf(item.city),item.kq,filters.city)
                }

                if(filters.city?.indexOf(item.city) != -1){
                  let obj = {
                    text:item.bm,
                    value:item.bm
                  }
                  res.push(obj)
                }

              })
            }
            console.log('kqFilter',res)
            // setKqFilters(
            //   res
            //   )
          }}
        >

        </Table>
        <div>用户使用记录</div>
        <Table 
          columns={[
          {
            title: '序号',
            dataIndex: 'index',
            // key: 'index',
            render(value, record, index) {
              return index + 1
            },
          },
          {
            title: '会员卡id',
            dataIndex: 'cardid',

          },
          {
            title: '会员卡名称',
            dataIndex: 'card_name',

          },
          {
            title: '使用时间',
            dataIndex: 'create_time',
            render(value, record, index) {
              return value ? moment(value).format("YYYY-MM-DD HH:mm") : ""
            },

          },
        ]}
          dataSource={userInfo.historyInfo}
          onChange={function(pagination, filters){
            return
          }}
        >

        </Table>
        </Modal>

        <Modal 
          title={"绑定会员卡"}
          visible={addCardModal}
          onCancel={() =>{
            setAddCardModal(false)
          }}
          onOk={() =>{
            form3.validateFields().then(res => {
              console.log('3232',res)
              let target = cardList.find((item) => item.id == res.card) 
              axios.post(`/liyongqi/use-card-add`,qs.stringify({
                userid:userInfo.baseInfo.id,
                use_time:0,
                cardid:target.id}))
              .then((res) => {
                console.log(res)
                if(res.data.code == 200){

                  setShouldUpdate2(+new Date())
                  setAddCardModal(false)
                  form3.setFieldsValue({name:"",card:""})
                }

              })
            })
          }}
        >
        <Form
          form={form3}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '输入用户名称' }]}
          >
            <Input  disabled/>
          </Form.Item>

          <Form.Item
            label="会员卡"
            name="card"
            rules={[{ required: true, message: '输入手机号' }]}
          >
            <Select 
                  options={cardList.map((item)=>{
                    item.label = item.name
                    item.value = item.id
                    return item

                  })}
            >

            </Select>
          </Form.Item>
        </Form>
        </Modal>
      </Tabs.TabPane>

      <Tabs.TabPane tab="类型卡列表" key="2">
        <Button type="primary" onClick={() => {setCreateModal2(true)}}>新建类型卡</Button>
        <Table 
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              // key: 'index',
              render(value, record, index) {
                return index + 1
              },
            },
            {
              title: '会员卡id',
              dataIndex: 'id',
  
            },
            {
              title: '卡名称',
              dataIndex: 'name',
  
            },
            {
              title: '可使用次数',
              dataIndex: 'count',
  
            },
            {
              title: '金额',
              dataIndex: 'money',
  
            },
  
            
        ]}
          dataSource={cardList}
          onChange={function(pagination, filters){
            return
            console.log('filters',filters)
            let res = []
            if(filters.city.length != 0){
              data.forEach(item => {
                console.log('data.forEach...',filters.city?.indexOf(item.kq),item.kq,filters.city)
                if(filters.city?.indexOf(item.city) != -1){
                  console.log('2data.forEach...',filters.city?.indexOf(item.city),item.kq,filters.city)
                }

                if(filters.city?.indexOf(item.city) != -1){
                  let obj = {
                    text:item.bm,
                    value:item.bm
                  }
                  res.push(obj)
                }

              })
            }
            console.log('kqFilter',res)
            // setKqFilters(
            //   res
            //   )
          }}
        >

        </Table>
        <Modal 
          title={"新建用户"}
          visible={createModal2}
          onCancel={() =>{
            setCreateModal2(false)
          }}
          onOk={() =>{
            form.validateFields().then(res => {
              
              axios.post(`/liyongqi/card-add`,qs.stringify(res))
              .then((res) => {
                console.log(res)
                if(res.data.code == 200){

                  setShouldUpdate(+new Date())
                  setCreateModal2(false)
                  form.setFieldsValue({name:"",count:"",money:""})
                }

              })
            })
          }}
        >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="卡名称"
            name="name"
            rules={[{ required: true, message: '输入卡名称' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="使用次数"
            name="count"
            rules={[{ required: true, message: '输入使用次数' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="金额"
            name="money"
            rules={[{ required: true, message: '输入金额' }]}
          >
            <Input />
          </Form.Item>
        </Form>
        </Modal>

        <Modal 
          title={"查看用户"}
          width={1250}
          visible={lookModalVisible}
          onCancel={() =>{
            setLookModalVisible(false)
          }}
          footer={null}
          onOk={() =>{
            form.validateFields().then(res => {
              
              axios.post(`/liyongqi/user-add`,qs.stringify(res))
              .then((res) => {
                console.log(res)
                if(res.data.code == 200){

                  setShouldUpdate(+new Date())
                  setCreateModal(false)
                  form.setFieldsValue({name:"",phone:""})
                }

              })
            })
          }}
        >
        <div>用户基本信息</div>
        <Form

          form={form2}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '输入用户名称' }]}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            label="手机号"
            name="phone"
            rules={[{ required: true, message: '输入手机号' }]}
          >
            <Input disabled/>
          </Form.Item>
        </Form>

        <div>用户拥有卡片         <Button type="primary" onClick={() => {
          setAddCardModal(true)
          
        }}>为用户添加卡片</Button></div>
        <Table 
          columns={[
          {
            title: '序号',
            dataIndex: 'index',
            // key: 'index',
            render(value, record, index) {
              return index + 1
            },
          },
          {
            title: '会员卡id',
            dataIndex: 'cardid',

          },
          {
            title: '卡名称',
            dataIndex: 'card_name',

          },
          {
            title: '使用次数',
            dataIndex: 'card_count',

          },
          {
            title: '金额',
            dataIndex: 'card_money',

          },

          {
            title: '使用次数',
            dataIndex: 'use_time',

          },
          
          {
            title: '剩余次数',
            render(value, record, index) {
              return record.card_count - record.use_time
            },

          },

          {
            title: '操作',
            render(value, record, index) {
              return <Button onClick={() => {
                showConfirm(record,userInfo.baseInfo)
              }}>
                使用
              </Button>
            },

          },
        ]}
          dataSource={userInfo.cardInfo}
          onChange={function(pagination, filters){
            return
            console.log('filters',filters)
            let res = []
            if(filters.city.length != 0){
              data.forEach(item => {
                console.log('data.forEach...',filters.city?.indexOf(item.kq),item.kq,filters.city)
                if(filters.city?.indexOf(item.city) != -1){
                  console.log('2data.forEach...',filters.city?.indexOf(item.city),item.kq,filters.city)
                }

                if(filters.city?.indexOf(item.city) != -1){
                  let obj = {
                    text:item.bm,
                    value:item.bm
                  }
                  res.push(obj)
                }

              })
            }
            console.log('kqFilter',res)
            // setKqFilters(
            //   res
            //   )
          }}
        >

        </Table>
        <div>用户使用记录</div>
        <Table 
          columns={[
          {
            title: '序号',
            dataIndex: 'index',
            // key: 'index',
            render(value, record, index) {
              return index + 1
            },
          },
          {
            title: '会员卡id',
            dataIndex: 'cardid',

          },
          {
            title: '会员卡名称',
            dataIndex: 'card_name',

          },
          {
            title: '使用时间',
            dataIndex: 'create_time',
            render(value, record, index) {
              return value ? moment(value).format("YYYY-MM-DD HH:mm") : ""
            },

          },
        ]}
          dataSource={userInfo.historyInfo}
          onChange={function(pagination, filters){
            return
          }}
        >

        </Table>
        </Modal>

        <Modal 
          title={"绑定会员卡"}
          visible={addCardModal}
          onCancel={() =>{
            setAddCardModal(false)
          }}
          onOk={() =>{
            form3.validateFields().then(res => {
              console.log('3232',res)
              let target = cardList.find((item) => item.id == res.card) 
              axios.post(`/liyongqi/use-card-add`,qs.stringify({
                userid:userInfo.baseInfo.id,
                use_time:0,
                cardid:target.id}))
              .then((res) => {
                console.log(res)
                if(res.data.code == 200){

                  setShouldUpdate2(+new Date())
                  setAddCardModal(false)
                  form3.setFieldsValue({name:"",card:""})
                }

              })
            })
          }}
        >
        <Form
          form={form3}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '输入用户名称' }]}
          >
            <Input  disabled/>
          </Form.Item>

          <Form.Item
            label="会员卡"
            name="card"
            rules={[{ required: true, message: '输入手机号' }]}
          >
            <Select 
                  options={cardList.map((item)=>{
                    item.label = item.name
                    item.value = item.id
                    return item

                  })}
            >

            </Select>
          </Form.Item>
        </Form>
        </Modal>
      </Tabs.TabPane>
      <Tabs.TabPane tab="使用记录" key="3">
        内容 2
      </Tabs.TabPane>
  </Tabs>
  </div>


    
  </>
}


export default ZhangPages