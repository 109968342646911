import './App.css'


import Login from './component/login/index'

import React from 'react'
import { HashRouter, Route } from 'react-router-dom'

import PentaPage from './pages/pentaPage'

import ProctectedRoute from './utils/proctected.router'
// 宝宝视频页面
import ZhangLive from './pages/zhangLive'
import BaobaoPage from './pages/baobaoPage'

import ContactMe from './pages/contactMe'


// 
import questionAndAns from './pages/questionAndAns'

import hbsk from './pages/hbsk'
import YQ from './pages/yq'



function App() {
  return (
    <>
      <div className='app'>
        <HashRouter>
          <div>
          {/* <ProctectedRoute exact path="/pentakill" component={PentaPage} /> */}
          <Route exact path="/zhanglive" component={ZhangLive} />
          <Route exact path="/baobaopage" component={BaobaoPage} />
          {/* <ProctectedRoute exact path="/zhanglive" component={ZhangLive} />
          <ProctectedRoute exact path="/baobaopage" component={BaobaoPage} /> */}
          <ProctectedRoute exact path="/questionAndAns" component={questionAndAns} />
          <ProctectedRoute exact path="/hbsk" component={hbsk} />
          <ProctectedRoute exact path="/yq" component={YQ} />
          <ProctectedRoute exact path="/" component={Login} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/register' component={Login} />
          <Route exact path='/contact-me' component={ContactMe} />
          </div>
        </HashRouter>
      </div>
    </>
  );
}

export default App;
