/*
 * @Author: 王子龙
 * @Date: 2022-08-16 16:34:30
 * @LastEditTime: 2023-01-29 15:12:14
 * @LastEditors: wangzilong1997 1321787581@qq.com
 * @Descripttion: 简单自定义table
 */

import { Button, Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';

interface ICustomTable extends TableProps<any> {
  request: Function;
  shouldUpdate?: Array<any>;
  pagination?:any;
  // 这个东西（本来就应该写在pagination里面 。。。。 不应该使用）
  defaultCurrent?:number;
  refresh?:Array<any>
}

const CustomTable: React.FC<ICustomTable> = (props) => {
  const { request, shouldUpdate = [], pagination={},defaultCurrent,refresh = []} = props;
  
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(defaultCurrent || 1);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // 多个请求同时进行时 可能出现请求完之后 立马取消loading 后续请求没完成的情况下
  const [loadingTask,setLoadingTask] = useState(0)

  const [updateDep,setUpdateDep]  = useState(-1)

  if(defaultCurrent) {
    pagination.defaultCurrent = defaultCurrent
  }

  
  useEffect( () => {
    const init = async() => {
      // setLoading(true);
      setLoadingTask(e  => e + 1)
      let res = await request(current, pageSize);
      // 需要保持 res = {success:boolean,data = [] | data.list = [] } 符合接口格式
      if (res.success) {
        if (res.data.list) {
          setDataSource(res.data.list);
          setTotal(res.total);
        } else {
          setDataSource(res.data);
          setTotal(res.total);
        }
      }else {
        setDataSource([]);
        setTotal(0);
      }
      // setLoading(false)
      setLoadingTask(e  => e - 1)
    }
    init()
  }, [current, pageSize,updateDep,...refresh]);

  useEffect(()=>{
    setCurrent(defaultCurrent || 1)
    setPageSize(10)
    setUpdateDep(+new Date())
  },[...shouldUpdate])

  useEffect(()=>{
  
  },[...refresh])

  return (
    <Table
      dataSource={dataSource}
      loading={loadingTask != 0}
      {...props}
      pagination={
        Object.assign({
          // defaultCurrent: current,
          current,
          pageSize: pageSize,
          showSizeChanger: true,
          total: total,
          onShowSizeChange(current:number, size:number) {
            setPageSize(size);
          },
          onChange(page:number, pageSize:number) {
            setCurrent(page);
          },
          showQuickJumper: {goButton:<Button 
          style={{
            border: 'none',
            borderRadius: '4px !important',
            marginLeft: '10px',
            boxShadow: '1px 1px 3px 0px black',
            height: '32px !important'
          }}>go</Button>
          },
        },pagination)
      }
    ></Table>
  );
};

export default CustomTable;
