/*
 * @Author: 王子龙
 * @Date: 2022-05-14 19:54:39
 * @LastEditTime: 2022-08-08 22:48:29
 * @LastEditors: 王子龙
 * @Descripttion: 
 */

import Header from '@/component/header2/index'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Table, Space, Tag} from 'antd';
import styles from './index.less'

const { TextArea } = Input;

const ZhangPages: React.FC<any> = (props) => {
  const [arr,setArr] = useState([
    'F:  TTT  TTC',
    'S:  TCT  TCC  TCA  TCG',
    'Y:  TAT  TAC',
    'C:  TGT  TGC',
    'W:  TGG',
    'L:  TTA  TTG  CTT  CTC  CTA  CTG',
    'P:  CCT  CCC  CCA  CCG',
    'H:  CAT  CAC',
    'Q:  CAA  CAG',
    'R:  CGT  CGC  CGA  CGG',
    'I:  ATT  ATC  ATA',
    'M:  ATG',
    'T:  ACT  ACC  ACA  ACG',
    'N:  AAT  AAC',
    'K:  AAA  AAG',
    'S:  AGT  AGC',
    'R:  AGA  AGG',
    'V:  GTT  GTC  GTA  GTG',
    'A:  GCT  GCC  GCA  GCG',
    'D:  GAT  GAC',
    'E:  GAA  GAG',
    'G:  GGT  GGC  GGA  GGG',
  ])

  const [str,setStr] = useState('')
  const [targetArr,setTargetArr] = useState([])

  const [printRes,setPrintRes] = useState([])

  const [form] = Form.useForm();

  const [tableData,setTableData] = useState([])



  function dealData(str,targetArr=[]) {
    targetArr = targetArr.map(item => {
      return item.trim().replace('\n','')
    })
    console.log('处理数据执行',str,targetArr)
    var arrObj = arr.reduce((prev,cur,index) => {
      let temp = cur.split(":")
        console.log(temp)
        prev[temp[0]] = temp[1].trim().split("  ")
        return prev
    },{})
    var map = new Map();
    for(let key in arrObj){
        console.log(key,arrObj)
        for(let i=0;i<arrObj[key].length;i++){
            map.set(arrObj[key][i],key)
            // console.log(map)
        }
    }

    var resArr = []
    for(let i=0;i< str.length;i=i+3){
        // debugger
        // if(i+9 >= str.length) {
        //   break
        // }
        var target = str.slice(i,i+9)
        // console.log(target)
        let res = transfrom(target,targetArr)
        if(res){
          resArr.push([i,target,res[0],res[1]])
          console.log('找到当前突变位置',i,target,res[0],res[1])
        }
        
    }

    return resArr
    
    function transfrom(str,targetArr = []){
      var str1 = str.slice(0,3)
      var str2 = str.slice(3,6)
      var str3 = str.slice(6,9)
      // console.log(str1,str2,str3)
      // console.log(map)
      var strM1 = map.has(str1) ? arrObj[map.get(str1)] : [str1]
      var strM2 = map.has(str2) ? arrObj[map.get(str2)] : [str2]
      var strM3 = map.has(str3) ? arrObj[map.get(str3)] : [str3]
      // console.log(strM1,strM2,strM3)
      for(let x =0;x<strM1.length;x++){
        for(let y=0;y<strM2.length;y++){
          for(let z=0;z<strM3.length;z++){
            // console.log(strM1[x]+strM2[y]+strM3[z])
            let temp = strM1[x]+strM2[y]+strM3[z]
            // if(targetArr.indexOf(temp) != -1){
            //   return true
            // }
            for(let j=0;j<targetArr.length;j++){
              if(temp.indexOf(targetArr[j]) != -1){
                return [temp,targetArr[j]]
              }
            }
          }
        }
      }
      return false
  
    }
  }

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values);
      setStr(values.str)
      setTargetArr(values.targetArr)

      let res:any = dealData(values.str,values.targetArr.split(','))
      console.log('rrrrrrrrressssssss',res)
      setTableData(res.map(item => {
        return {
          index:item[0],
          before:item[1],
          after:item[2],
          target:item[3],
        }
      }))
      setPrintRes(res)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  useEffect(() => {
    // let str =  'ATGGAAGACGCCAAAAACATAAAGAAAGGCCCGGCGCCATTCTATCCGCTGGAAGATGGAACCGCTGGAGAGCAACTGCATAAGGCTATGAAGAGATACGCCCTGGTTCCTGGAACAATTGCTTTTACAGATGCACATATCGAGGTGGACATCACTTACGCTGAGTACTTCGAAATGTCCGTTCGGTTGGCAGAAGCTATGAAACGATATGGGCTGAATACAAATCACAGAATCGTCGTATGCAGTGAAAACTCTCTTCAATTCTTTATGCCGGTGTTGGGCGCGTTATTTATCGGAGTTGCAGTTGCGCCCGCGAACGACATTTATAATGAACGTGAATTGCTCAACAGTATGGGCATTTCGCAGCCTACCGTGGTGTTCGTTTCCAAAAAGGGGTTGCAAAAAATTTTGAACGTGCAAAAAAAGCTCCCAATCATCCAAAAAATTATTATCATGGATTCTAAAACGGATTACCAGGGATTTCAGTCGATGTACACGTTCGTCACATCTCATCTACCTCCCGGTTTTAATGAATACGATTTTGTGCCAGAGTCCTTCGATAGGGACAAGACAATTGCACTGATCATGAACTCCTCTGGATCTACTGGTCTGCCTAAAGGTGTCGCTCTGCCTCATAGAACTGCCTGCGTGAGATTCTCGCATGCCAGAGATCCTATTTTTGGCAATCAAATCATTCCGGATACTGCGATTTTAAGTGTTGTTCCATTCCATCACGGTTTTGGAATGTTTACTACACTCGGATATTTGATATGTGGATTTCGAGTCGTCTTAATGTATAGATTTGAAGAAGAGCTGTTTCTGAGGAGCCTTCAGGATTACAAGATTCAAAGTGCGCTGCTGGTGCCAACCCTATTCTCCTTCTTCGCCAAAAGCACTCTGATTGACAAATACGATTTATCTAATTTACACGAAATTGCTTCTGGTGGCGCTCCCCTCTCTAAGGAAGTCGGGGAAGCGGTTGCCAAGAGGTTCCATCTGCCAGGTATCAGGCAAGGATATGGGCTCACTGAGACTACATCAGCTATTCTGATTACACCCGAGGGGGATGATAAACCGGGCGCGGTCGGTAAAGTTGTTCCATTTTTTGAAGCGAAGGTTGTGGATCTGGATACCGGGAAAACGCTGGGCGTTAATCAAAGAGGCGAACTGTGTGTGAGAGGTCCTATGATTATGTCCGGTTATGTAAACAATCCGGAAGCGACCAACGCCTTGATTGACAAGGATGGATGGCTACATTCTGGAGACATAGCTTACTGGGACGAAGACGAACACTTCTTCATCGTTGACCGCCTGAAGTCTCTGATTAAGTACAAAGGCTATCAGGTGGCTCCCGCTGAATTGGAATCCATCTTGCTCCAACACCCCAACATCTTCGACGCAGGTGTCGCAGGTCTTCCCGACGATGACGCCGGTGAACTTCCCGCCGCCGTTGTTGTTTTGGAGCACGGAAAGACGATGACGGAAAAAGAGATCGTGGATTACGTCGCCAGTCAAGTAACAACCGCGAAAAAGTTGCGCGGAGGAGTTGTGTTTGTGGACGAAGTACCGAAAGGTCTTACCGGAAAACTCGACGCAAGAAAAATCAGAGAGATCCTCATAAAGGCCAAGAAGGGCGGAAAGATCGCCGTGTAA'
    // var targetArr = ['TGGAC']
    let res = dealData(str,targetArr)
    console.log('rrrrrrrrressssssss',res)
  },[])

  useEffect(() => {
    console.log('结果发生变化',printRes)
  },[printRes])

  const columns: any = [
    {
      title: '下标',
      dataIndex: 'index',
      key: 'index',
      
    },
    {
      title: '原序列',
      dataIndex: 'before',
      key: 'before',
    },
    {
      title: '突变后序列',
      dataIndex: 'after',
      key: 'after',
    },
    {
      title: '查找序列',
      dataIndex: 'target',
      key: 'target',
    },
  ];
  return <>
    <Header />
    <div className={styles.contianer}>
      <Form form={form} initialValues={{str:'ATGGAAGACGCCAAAAACATAAAGAAAGGCCCGGCGCCATTCTATCCGCTGGAAGATGGAACCGCTGGAGAGCAACTGCATAAGGCTATGAAGAGATACGCCCTGGTTCCTGGAACAATTGCTTTTACAGATGCACATATCGAGGTGGACATCACTTACGCTGAGTACTTCGAAATGTCCGTTCGGTTGGCAGAAGCTATGAAACGATATGGGCTGAATACAAATCACAGAATCGTCGTATGCAGTGAAAACTCTCTTCAATTCTTTATGCCGGTGTTGGGCGCGTTATTTATCGGAGTTGCAGTTGCGCCCGCGAACGACATTTATAATGAACGTGAATTGCTCAACAGTATGGGCATTTCGCAGCCTACCGTGGTGTTCGTTTCCAAAAAGGGGTTGCAAAAAATTTTGAACGTGCAAAAAAAGCTCCCAATCATCCAAAAAATTATTATCATGGATTCTAAAACGGATTACCAGGGATTTCAGTCGATGTACACGTTCGTCACATCTCATCTACCTCCCGGTTTTAATGAATACGATTTTGTGCCAGAGTCCTTCGATAGGGACAAGACAATTGCACTGATCATGAACTCCTCTGGATCTACTGGTCTGCCTAAAGGTGTCGCTCTGCCTCATAGAACTGCCTGCGTGAGATTCTCGCATGCCAGAGATCCTATTTTTGGCAATCAAATCATTCCGGATACTGCGATTTTAAGTGTTGTTCCATTCCATCACGGTTTTGGAATGTTTACTACACTCGGATATTTGATATGTGGATTTCGAGTCGTCTTAATGTATAGATTTGAAGAAGAGCTGTTTCTGAGGAGCCTTCAGGATTACAAGATTCAAAGTGCGCTGCTGGTGCCAACCCTATTCTCCTTCTTCGCCAAAAGCACTCTGATTGACAAATACGATTTATCTAATTTACACGAAATTGCTTCTGGTGGCGCTCCCCTCTCTAAGGAAGTCGGGGAAGCGGTTGCCAAGAGGTTCCATCTGCCAGGTATCAGGCAAGGATATGGGCTCACTGAGACTACATCAGCTATTCTGATTACACCCGAGGGGGATGATAAACCGGGCGCGGTCGGTAAAGTTGTTCCATTTTTTGAAGCGAAGGTTGTGGATCTGGATACCGGGAAAACGCTGGGCGTTAATCAAAGAGGCGAACTGTGTGTGAGAGGTCCTATGATTATGTCCGGTTATGTAAACAATCCGGAAGCGACCAACGCCTTGATTGACAAGGATGGATGGCTACATTCTGGAGACATAGCTTACTGGGACGAAGACGAACACTTCTTCATCGTTGACCGCCTGAAGTCTCTGATTAAGTACAAAGGCTATCAGGTGGCTCCCGCTGAATTGGAATCCATCTTGCTCCAACACCCCAACATCTTCGACGCAGGTGTCGCAGGTCTTCCCGACGATGACGCCGGTGAACTTCCCGCCGCCGTTGTTGTTTTGGAGCACGGAAAGACGATGACGGAAAAAGAGATCGTGGATTACGTCGCCAGTCAAGTAACAACCGCGAAAAAGTTGCGCGGAGGAGTTGTGTTTGTGGACGAAGTACCGAAAGGTCTTACCGGAAAACTCGACGCAAGAAAAATCAGAGAGATCCTCATAAAGGCCAAGAAGGGCGGAAAGATCGCCGTGTAA',targetArr:'TGGAC'}}>
        <Form.Item
          name="str"
          label="全序列"
          rules={[
            {
              required: true,
              message: '宝宝输入全序列',
            },
          ]}
          
        >
          <TextArea rows={4} placeholder="输入密码子" maxLength={100000} />
        </Form.Item>
        <Form.Item
          name="targetArr"
          label="找序列"
          rules={[
            {
              required: true,
              message: '宝宝输入需要查找的序列',
            },
          ]}
          >
          <TextArea rows={4} placeholder="输入需要查找的子序列" maxLength={100000} />
        </Form.Item>
        <Form.Item >
          <Button type="primary" onClick={onCheck}>
            宝宝点击
          </Button>
      </Form.Item>
      </Form>


    </div>
    <div className={styles.showBorad}>
        {
          printRes.map((item,index) => {
            if(index == 0){
              return <span><span>{str.slice(0,item[0])}</span><span className={styles.mark} title={item[2]}><sub>{item[0]}</sub>{str.slice(item[0],item[0]+9)}</span></span>
            }else if(index == printRes.length -1){
              return <span>
                <span>{str.slice(printRes[index-1][0],str.length-1)}</span>
              </span>
            }else{
              return <span>
              <span>{str.slice(printRes[index-1][0],printRes[index][0])}</span>
              <span className={styles.mark} title={item[2]}>
              <sub>{item[0]}</sub>{str.slice(printRes[index][0],printRes[index][0]+9)}
              </span>
            </span>
            }

          })
        }
    </div>
    <div className={styles.table}>
      <Table columns={columns} dataSource={tableData} >
      
      </Table>
    </div>


  </>
}


export default ZhangPages