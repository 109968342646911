/*
 * @Author: 王子龙
 * @Date: 2022-05-14 19:54:39
 * @LastEditTime: 2022-08-08 22:48:29
 * @LastEditors: 王子龙
 * @Descripttion: 
 */

import Header from '@/component/header2/index'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Table, Space, Tag} from 'antd';
import styles from './index.less'

import Icon from '@/asset/appIcon.png'

const { TextArea } = Input;

const ZhangPages: React.FC<any> = (props) => {
  return <div className={styles.page}>
  <div>
  <div className={styles.title}>经典海龟汤</div>
  <img className={styles.icon} src={Icon}></img>

  <div className={styles.content}>
    <div className={styles.memo}>
      海龟汤故事列表合集，可以和好朋友一起海龟汤的好帮手!!!
    </div>
    <div className={styles.contactMe}>联系我</div>
    <div className={styles.line}>
      <div className={styles.lineTitle}>邮箱:</div>
      <div>turtlesoup@qq.com</div>
    </div>
    <div className={styles.line}>
      <div className={styles.lineTitle}>电话:</div>
      <div>+86 15311321736</div>
    </div>
  </div>
  </div>

  </div>
}


export default ZhangPages