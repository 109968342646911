/*
 * @Author: 王子龙
 * @Date: 2022-05-14 19:54:39
 * @LastEditTime: 2023-01-29 15:17:28
 * @LastEditors: wangzilong1997 1321787581@qq.com
 * @Descripttion: 
 */

import Header from '@/component/header/index'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Table, Space, Tag, Radio, Select} from 'antd';
// import axios from 'axios';

import { axios }from '../../utils/reques'

import CustomTable from '../../component/CustomTable';

import './index.less'
const { TextArea } = Input;

const ZhangPages: React.FC<any> = (props) => {
  const [originData,setOrginData] = useState([])
  const  [data,setData] = useState([])
  const [storyid,setStoryid] = useState(null)
  const [update,setUpdate] = useState(+new Date())

  const [selectValue,setSelectValue] = useState(0)
  const [selectOpt,setSelectOpt] = useState(Array.from(Array(201).keys()).map(item =>{ return {value:item,label:item}}))
  useEffect(() => {
    console.log('请求接口数据')
    // axios.get(`/turtleSoup/question/questionAndAns/list`)
    // .then((res) => {
    //   console.log('ressss', res)
    //   setData(res.data.data.result)
    //   setOrginData(res.data.data.result)
    // })
    // setSelectOpt(Array.from(Array(201).keys()).map(item =>{ return {value:item,label:item}}))
    // console.log('2321',rray.from(Array(201).keys()).map(item =>{ return {value:item,label:item}}))
  },[])
  useEffect(() => {
    console.log('请求接口数据')
    // axios.get(`/turtleSoup/question/questionAndAns/list`)
    // .then((res) => {
    //   console.log('ressss', res)
    //   setData(res.data.data.result)
    //   setOrginData(res.data.data.result)
    // })
  },[update])
  useEffect(()=>{
    console.log(storyid,originData,typeof storyid != 'number',originData.filter(i => +i.storyid === +storyid))

    if(storyid === ''){
      if(!storyid) {
        setData(originData)
        return 
      }
      return
    }else{

      // axios.get(`/turtleSoup/question/questionAndAns/list`)
      // .then((res) => {
      //   console.log('ressss', res)
      //   setData(res.data.data.result)
      // })
      setData(JSON.parse(JSON.stringify(originData.filter(i => +i.storyid === +storyid))))
      console.log('3232323',data)
    }
  },[storyid])
  
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',

    },
    // {
    //   title: '故事id',
    //   dataIndex: 'storyid',
    //   key: 'storyid',
    //   // filters:[
    //   //   // {
    //   //   //   text:0,
    //   //   //   value:0
    //   //   // },
    //   // ],
    //   // onFilter: (value: string, record) => record.storyid == value,
    // },
    
    {
      title: '下标',
      dataIndex: 'index',
      key: 'index',
    },

    {
      title: '故事标题',
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: '汤面',
    //   dataIndex: 'noodle',
    //   key: 'noodle',
    //   width:'20%'
    // },
    // // {
    //   title: '汤底',
    //   dataIndex: 'soup',
    //   key: 'soup',
    //   width:'20%'
    // },
    {
      title: '用户提问',
      dataIndex: 'tips',
      key: 'tips',
    },
    {
      title: '建议回答',
      dataIndex: 'answer',
      key: 'answer',
      width:'40%',
      render:(text, record, index)=>{

        return     <Radio.Group onChange={(e) => radioChange(e,record.id)} value={text}>
        <Radio value={'true'}>是</Radio>
        <Radio value={'false'}>不是</Radio>
        <Radio value={'NaN'}>与此无关</Radio>
        <Radio value={'useless'}>无用的选项</Radio>
      </Radio.Group>
      }
    },
    {
      title: '回答来源',
      dataIndex: 'answerfrom',
      key: 'answerfrom',
      render:(_) => {
        if(_ == 'ts'){
          return 'ai'
        } else if(_ == 'people'){
          return '宇宙无敌大帅哥'
        } else {
          return _
        }
      }
    },
    {
      title: '修改时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      render:(_) =>{
        return _ && new Date(_).toLocaleDateString() + ' ' + new Date(_).toLocaleTimeString() 
      }
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render:(_) =>{
        return _ && new Date(_).toLocaleDateString() + ' '+   new Date(_).toLocaleTimeString() 
      }
    },
  ];

  const customGetDataFunc = async (current:number ,pageSize:number,id:number = selectValue) => {
    let res 
    let temp = id !== undefined ? `&storyid=${id}`: ''
    await  axios.get(`/turtleSoup/question/questionAndAns/list?page=${current}&pageSize=${pageSize}` + temp)
    .then((rres) => {
      console.log('ressss', rres)
      rres.data.data.list = rres.data.data.result
      rres.data.data.list = rres.data.data.list.map((item,index) => {
        item.index = index
        return item
      })
      res = rres
      res.data.total = res.data.data.total
    })
    console.log('232',res)
    // res.data = res.data.map((item:any,idx:any) => {
    //   item.order = res.total - ((current - 1) * pageSize + idx) 
    //   return item
    // })
    return res.data
  }

  const radioChange = (e,id) => {
    console.log('切换回答',e.target.value)
    axios.get(`/turtleSoup/question/questionAndAns/update?answer=${e.target.value}&id=${id}`)
    .then((res) => {
      console.log('ressss', res)
      setUpdate(Math.floor(+new Date() / 1000))
    })
  }
  function selectHandleChange(params:any) {
    console.log('select')
    setSelectValue(params)
  }

  return <>
    <Header />
    故事id：
    <Select
      defaultValue={selectValue}
      style={{ width: 120 }}
      onChange={selectHandleChange}
      options={selectOpt}
    />
    {/* <Input type='number' onChange={(e)=>{
      setStoryid(e.target.value)
    }}></Input> */}
    {/* <Button>确定</Button> */}
    {/* <div>
      {data && data.map(item => {
        return <div>
          {item.storyid} -- {item.tips}
        </div>

      })}
    </div> */}
    {/* <Table 
      // style={{'background':'red'}} 
      dataSource={data} 
      columns={columns} />; */}

      <CustomTable
      className='dark'
      columns={columns}
      request={customGetDataFunc}
      shouldUpdate={[selectValue]}
      refresh={[update]}
      >

      </CustomTable>

  </>
}


export default ZhangPages