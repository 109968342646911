import { message } from 'antd';
import axios from 'axios';
import qs from 'qs';
const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log(response)
    if(response.data.code != 200){

      message.error(response.data.data)
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const token = await requestNewToken();
      console.log('获取新token')
      localStorage.setItem('token', token);
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);
async function requestNewToken(){
  let res = await axios.post('/users/api/user/login', qs.stringify({ name: localStorage.getItem('username'), password: localStorage.getItem('password') }))
  console.log('ressss',res)
  window.localStorage.setItem('userid', res.data.data.userid)
  window.localStorage.setItem('username', res.data.data.username)
  window.localStorage.setItem('token', res.data.data.token)
  window.localStorage.setItem('password',res.data.data.password)
  return res.data.data.token
}

export  { instance as axios }