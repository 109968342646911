/*
 * @Author: 王子龙
 * @Date: 2022-05-14 19:54:39
 * @LastEditTime: 2023-02-04 09:21:53
 * @LastEditors: wangzilong1997 1321787581@qq.com
 * @Descripttion: 
 */

import Header from '@/component/header/index'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Table, Space, Tag, Radio} from 'antd';
// import axios from 'axios';
import { axios }from '../../utils/reques'

import CustomTable from '../../component/CustomTable';
const { TextArea } = Input;

const ZhangPages: React.FC<any> = (props) => {
  const [originData,setOrginData] = useState([])
  const  [data,setData] = useState([])
  const [storyid,setStoryid] = useState(null)
  const [update,setUpdate] = useState(+new Date())
  const [cityFilters,setCityFilters] = useState([])
  const [kqFilters,setKqFilters] = useState([])
  useEffect(() => {
    console.log('请求接口数据')
    axios.get(`/hbsk/list`)
    .then((res) => {
      console.log('ressss', res)

      let data = JSON.parse(res.data.data)

      let result = []
      let index = 0
      data.forEach(city => {
        city.kqArr.forEach(kq => {
          kq.bmArr.forEach(bm => {
            bm.dwArr.forEach(dw => {
              dw.info.sum = +dw.info.waitAuditC + +dw.info.auditPassC
              dw.info.blb = (+dw.info.sum / +dw.info.str6).toFixed(2)
              let item = {
                index:index,
                city:city.city,
                kq:kq.kq,
                bm:bm.bm,
                dw:dw.dw,
                info:dw.info
              }
              index = index + 1
              result.push(item)
            })
          })
        })
      });

      console.log('解析出来每一条数据结果',result,JSON.parse(res.data.data))
      setData(result)
      setOrginData(JSON.parse(res.data.data))
      setCityFilters(JSON.parse(res.data.data).map(i => {return {text:i.city,value:i.city}}))

      // let filterTargetStr = JSON.parse(JSON.stringify(data,(key,value)=>{
      //   // console.log(key,value)
      //   if(value.hasOwnProperty('city')){
      //     value.text = value['city']
      //     value.value = value['city']
      //   }else if(value.hasOwnProperty('kq')){
      //     value.text = value['kq']
      //     value.value = value['kq']
      //     // return undefined
      //   }else if(value.hasOwnProperty('bm')){
      //     value.text = value['bm']
      //     value.value = value['bm']
      //     // return undefined
      //   }else if(value.hasOwnProperty('dw')){

      //     value.text = value['dw']
      //     value.value = value['dw']
      //     value.info = ''
      //     // return undefined
      //   }
         
      //   return value
      // }).replaceAll('kqArr','children').replaceAll('bmArr','children').replaceAll('dwArr','children'))
      // console.log('filterTargetStr',filterTargetStr)

      // setCityFilters(filterTargetStr)
    })
  },[])
  


  return <>
    <Header />
    <Table 
      columns={[
      {
        title: 'index',
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: 'city',
        dataIndex: 'city',
        key: 'city',
        // filters: [
        //   {
        //     text: '秦皇岛',
        //     value: '秦皇岛',
        //   },
        //   {
        //     text: '保定',
        //     value: '保定',
        //   },
        // ],
        filters: cityFilters,
        onFilter: (value: string, record) => {
          console.log(value,record)
          return record.city.indexOf(value) === 0
        },
      },
      {
        title: 'kq',
        dataIndex: 'kq',
        // filters: kqFilters,
        // onFilter: (value: string, record) => record.kq.indexOf(value) === 0,
      },
      {
        title: 'bm',
        dataIndex: 'bm',
        key: 'bm',
      },
      {
        title: 'waitAuditC',
        dataIndex: 'waitAuditC',
        key: 'waitAuditC',
        render(value, record, index) {
          return record.info.waitAuditC
        },
        sorter: (a, b) => a.info.waitAuditC-b.info.waitAuditC,
      },
      {
        title: 'auditPassC',
        dataIndex: 'auditPassC',
        key: 'auditPassC',
        render(value, record, index) {
          return record.info.auditPassC
        },
        sorter: (a, b) => a.info.auditPassC-b.info.auditPassC,
      },
      {
        title: 'sum',
        dataIndex: 'sum',
        key: 'sum',
        render(value, record, index) {
          return record.info.sum
        },
        sorter: (a, b) => a.info.sum-b.info.sum,
      },
      {
        title: 'chargeC',
        dataIndex: 'chargeC',
        key: 'chargeC',
        render(value, record, index) {
          return record.info.chargeC
        },
        sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
      {
        title: 'str6',
        dataIndex: 'str6',
        key: 'str6',
        render(value, record, index) {
          return record.info.str6
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
      {
        title: 'blb',
        dataIndex: 'blb',
        key: 'blb',
        render(value, record, index) {
          return record.info.blb
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
        sorter: (a, b) => a.info.blb-b.info.blb,
      },
      {
        title: 'str11',
        dataIndex: 'str11',
        key: 'str11',
        render(value, record, index) {
          return record.info.str11
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
      {
        title: 'str7',
        dataIndex: 'str7',
        key: 'str7',
        render(value, record, index) {
          return record.info.str7
        },
        filters: [
          {
            text: '本科',
            value: '本科',
          },
          {
            text: '研究生',
            value: '研究生',
          },
        ],
        onFilter: (value: string, record) => {
          console.log('record',record,value)
          // if(value.length > 4) {
          //   value = value.replace('和无要求','')
          //   return !record.info.str9  || record.info.str9.indexOf(value) > -1
          // }
          return record.info.str7  &&  record.info.str7.indexOf(value) > -1
      
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
      {
        title: 'str5',
        dataIndex: 'str5',
        key: 'str5',
        render(value, record, index) {
          return record.info.str5
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
      {
        title: 'str9',
        dataIndex: 'str9',
        key: 'str9',
        render(value, record, index) {
          return record.info.str9
        },
        filters: [
          {
            text: '生物',
            value: '生物',
          },
          {
            text: '理学',
            value: '理学',
          },
          {
            text: '生物技术',
            value: '生物技术',
          },
          {
            text: '生物和无要求',
            value: '生物和无要求',
          },
          {
            text: '生物技术和无要求',
            value: '生物技术和无要求',
          },
          {
            text: '计算机',
            value: '计算机',
          },
          {
            text: '软件',
            value: '软件',
          },
        ],
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value: string, record) => {
          console.log('record',record,value)
          if(value.length > 4) {
            value = value.replace('和无要求','')
            return !record.info.str9  || record.info.str9.indexOf(value) > -1
          }
          return record.info.str9  &&  record.info.str9.indexOf(value) > -1
      
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
      {
        title: 'str10',
        dataIndex: 'str10',
        key: 'str10',
        render(value, record, index) {
          return record.info.str10
        },
        filters: [
          {
            text: '应届',
            value: '应届',
          },
          {
            text: '党员',
            value: '党员',
          },
          {
            text: '本科',
            value: '本科',
          },
          {
            text: '研究生',
            value: '研究生',
          },
          {
            text: '不要男生',
            value: '男生',
          },
        ],
        onFilter: (value: string, record) => {
          console.log('record',record,value)
          if(value.length > 4) {
            value = value.replace('和无要求','')
            return !record.info.str10  || record.info.str10.indexOf(value) > -1
          }
          if(value == '男生'){
            return record.info.str10 && record.info.str10.indexOf(value) == -1
          }
          return !record.info.str10 ||  record.info.str10.indexOf(value) > -1
      
        },
        // sorter: (a, b) => a.info.chargeC-b.info.chargeC,
      },
    ]}
      dataSource={data}
      onChange={function(pagination, filters){
        return
        console.log('filters',filters)
        let res = []
        if(filters.city.length != 0){
          data.forEach(item => {
            console.log('data.forEach...',filters.city?.indexOf(item.kq),item.kq,filters.city)
            if(filters.city?.indexOf(item.city) != -1){
              console.log('2data.forEach...',filters.city?.indexOf(item.city),item.kq,filters.city)
            }

            if(filters.city?.indexOf(item.city) != -1){
              let obj = {
                text:item.bm,
                value:item.bm
              }
              res.push(obj)
            }

          })
        }
        console.log('kqFilter',res)
        // setKqFilters(
        //   res
        //   )
      }}
    >

    </Table>
    
  </>
}


export default ZhangPages