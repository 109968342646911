/*
 * @Author: 王子龙
 * @Date: 2022-05-14 19:54:39
 * @LastEditTime: 2022-09-10 12:58:20
 * @LastEditors: wangzilong1997 1321787581@qq.com
 * @Descripttion: 
 */

import Header from '@/component/header2/index'
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Table, Space, Tag,Select} from 'antd';
import styles from './index.less'
const { Option } = Select;


const { TextArea } = Input;

const ZhangPages: React.FC<any> = (props) => {
  const [arr,setArr] = useState([
    'F:  TTT  TTC',
    'S:  TCT  TCC  TCA  TCG',
    'Y:  TAT  TAC',
    'C:  TGT  TGC',
    'W:  TGG',
    'L:  TTA  TTG  CTT  CTC  CTA  CTG',
    'P:  CCT  CCC  CCA  CCG',
    'H:  CAT  CAC',
    'Q:  CAA  CAG',
    'R:  CGT  CGC  CGA  CGG  AGA  AGG',
    'I:  ATT  ATC  ATA',
    'M:  ATG',
    'T:  ACT  ACC  ACA  ACG',
    'N:  AAT  AAC',
    'K:  AAA  AAG',
    'S:  AGT  AGC',
    'V:  GTT  GTC  GTA  GTG',
    'A:  GCT  GCC  GCA  GCG',
    'D:  GAT  GAC',
    'E:  GAA  GAG',
    'G:  GGT  GGC  GGA  GGG',
  ])

  const [str,setStr] = useState('')
  const [targetArr,setTargetArr] = useState([])

  const [printRes,setPrintRes] = useState([])

  const [form] = Form.useForm();

  const [map,setMap] = useState(new Map())
  const [arrObj,setArrObj] = useState({})

  const [dealData,setDealData] = useState({})

  const [clipStr,setClipStr] = useState([])

  const [clipIdxArr,setClipIdxArr] = useState([])

  const [copyObject,setCopyObject] = useState({})

  const [finalShow,setFinalShow] = useState('')

  useEffect(()=>{
    let arrObj = arr.reduce((prev,cur,index) => {
      let temp = cur.split(":")
        console.log(temp)
        prev[temp[0]] = temp[1].trim().split("  ")
        return prev
    },{})


    let map = new Map();
    for(let key in arrObj){
        console.log(key,arrObj)
        for(let i=0;i<arrObj[key].length;i++){
            map.set(arrObj[key][i],key)
            // console.log(map)
        }
    }
    console.log('输出map',map)
    console.log('输出arrObj',arrObj)
    setMap(map)
    setArrObj(arrObj)
  },[])
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values,map,arrObj);
      setStr(values.str)
      setTargetArr(values.targetArr.split(','))
      setFinalShow('')
      console.log('str',str,'targetArr',targetArr)
    } catch (errorInfo) {

      console.log('Failed:', errorInfo);
    }
  };

  useEffect(()=>{
    let hasTargetArr = targetArr.filter((item,idndex) => {
      if(str.indexOf(item) !== -1){
          return true
      }else{
          return false
      }
  })
  // 需要替换的集合 数据处理
  let resAll = {}
  for(let i=0;i<hasTargetArr.length;i++){
    let temp= str.matchAll(hasTargetArr[i])
    console.log('hasTargetArr[i]',hasTargetArr[i])
    let target = hasTargetArr[i]
    if(str.indexOf(target) != -1) {
      let extemp = [...temp]
      console.log('extemp',extemp)
      let result = []
      for(let i=0;i<extemp.length;i++){
        if((+extemp[i].index + 1) % 3 == 0){
          const temp1 = target.slice(0,6)
          const else1 = target[6]
          console.log('temp1',temp1)
          console.log('else1',else1)
          const temp1Arr = dealSix(temp1,map,arrObj)
          let temp1ArrAll = temp1Arr.map(item => {
            return item + else1 
          })

          result = [...new Set([...temp1ArrAll])]
        }
        if((+extemp[i].index + 1) % 3 == 1){
          let midArr = dealMid(target,map,arrObj)
          result = [...new Set([...midArr])]
        }
        if((+extemp[i].index + 1) % 3 == 2){

          const temp2 = target.slice(1,7)
          const else2 = target[0]
  
          console.log('temp2' + temp2,temp2)
          console.log('else2' + temp2,else2)
  
          const temp2Arr = dealSix(temp2,map,arrObj)
          let temp2ArrAll = temp2Arr.map(item => {
            return else2 + item
          })
          result = [...new Set([...temp2ArrAll])]
        }
        resAll[extemp[i].index] = {origin:target,arr:result}
      }




    }
    // debugger
  }
  console.log('整体处理完成的数据',hasTargetArr,resAll)
  setDealData(resAll)
  
  },[str,targetArr])

  useEffect(()=>{
    
    let arr = []

    for(let key in dealData){
      arr.push(key)
    }
    arr = arr.sort((a,b) => a-b)
    console.log('arr',arr)
    let resArr = []
    for(let i=0;i<arr.length;i++){
      resArr.push(str.slice(i == 0 ? 0 : +arr[i-1] + 7 ,+arr[i]))

      if(i == arr.length -1 ){
        resArr.push(str.slice(+arr[i] + 7 ))
      }
    }
    console.log('dealData发生变化',dealData,str,resArr)
    setClipStr(resArr)
    setClipIdxArr(arr)
  },[dealData])

  function dealMid(params,map,arrObj) {
    let midleft = params.slice(0,2)
    let mid  = params.slice(2,5)
    let midright = params.slice(5,7)
    let res = [params]
    if(map.has(mid)){
      let temp = arrObj[map.get(mid)]
      for(let i =0;i<temp.length;i++){
        res.push(midleft + temp[i] + midright)
      }
    }
    return res
  }


  function replacepos(text,start,stop,replacetext){

  let mystr = text.substr(0,start-1)+replacetext+text.substr(stop+1);

  return mystr;

  }

  // replacepos('测试文字csdn', 3, 4, "**")  

  function dealSix(params,map,arrObj) {

    let str1 = params.slice(0,3)
    let str2 = params.slice(3,6)
    let res = []
    let str1Arr = [str1]
    let str2Arr = [str2]
    console.log('str1str2',str1,str2)
    if(map.has(str1)){
      str1Arr = [...str1Arr,...arrObj[map.get(str1)]]
    }
    if(map.has(str2)){
      str2Arr = [...str2Arr,...arrObj[map.get(str2)]]
    }
    console.log()
    for(let i=0;i<str1Arr.length;i++){
      for(let j=0;j<str2Arr.length;j++){
        res.push(str1Arr[i] + str2Arr[j])
      }
    }
    return res
  }

  function makeList(params:type) {
    console.log('宝宝再点击进行了点击')
    console.log('输出校验对象',clipIdxArr,copyObject)
    let sign = true
    for(let i=0;i<clipIdxArr.length;i++){
      if(!copyObject[clipIdxArr[i]]){
        sign = false
      }
      
    }
    if(!sign){
      alert('宝宝选择项不全')
      return
    }
    let res =''
    for(let i=0;i<clipIdxArr.length;i++){
      res += clipStr[i] + copyObject[clipIdxArr[i]]
    }
    res += clipStr[clipStr.length -1]

    console.log('最后输出的序列',res)
    setFinalShow(res)

  }
  function handleChange(value,Option) {
    console.log('宝宝select',clipIdxArr,value,Option)
    let arr = Option.key.split(' ')
    console.log(arr)
    let temp = copyObject
    temp[arr[0]] = arr[1]

    setCopyObject(temp)

  }
  return <>
    <Header />
    <div className={styles.contianer}>
      <Form form={form} initialValues={{str:'ATGGAAGACGCCAAAAACATAAAGAAAGGCCCGGCGCCATTCTATCCGCTGGAAGATGGAACCGCTGGAGAGCAACTGCATAAGGCTATGAAGAGATACGCCCTGGTTCCTGGAACAATTGCTTTTACAGATGCACATATCGAGGTGGACATCACTTACGCTGAGTACTTCGAAATGTCCGTTCGGTTGGCAGAAGCTATGAAACGATATGGGCTGAATACAAATCACAGAATCGTCGTATGCAGTGAAAACTCTCTTCAATTCTTTATGCCGGTGTTGGGCGCGTTATTTATCGGAGTTGCAGTTGCGCCCGCGAACGACATTTATAATGAACGTGAATTGCTCAACAGTATGGGCATTTCGCAGCCTACCGTGGTGTTCGTTTCCAAAAAGGGGTTGCAAAAAATTTTGAACGTGCAAAAAAAGCTCCCAATCATCCAAAAAATTATTATCATGGATTCTAAAACGGATTACCAGGGATTTCAGTCGATGTACACGTTCGTCACATCTCATCTACCTCCCGGTTTTAATGAATACGATTTTGTGCCAGAGTCCTTCGATAGGGACAAGACAATTGCACTGATCATGAACTCCTCTGGATCTACTGGTCTGCCTAAAGGTGTCGCTCTGCCTCATAGAACTGCCTGCGTGAGATTCTCGCATGCCAGAGATCCTATTTTTGGCAATCAAATCATTCCGGATACTGCGATTTTAAGTGTTGTTCCATTCCATCACGGTTTTGGAATGTTTACTACACTCGGATATTTGATATGTGGATTTCGAGTCGTCTTAATGTATAGATTTGAAGAAGAGCTGTTTCTGAGGAGCCTTCAGGATTACAAGATTCAAAGTGCGCTGCTGGTGCCAACCCTATTCTCCTTCTTCGCCAAAAGCACTCTGATTGACAAATACGATTTATCTAATTTACACGAAATTGCTTCTGGTGGCGCTCCCCTCTCTAAGGAAGTCGGGGAAGCGGTTGCCAAGAGGTTCCATCTGCCAGGTATCAGGCAAGGATATGGGCTCACTGAGACTACATCAGCTATTCTGATTACACCCGAGGGGGATGATAAACCGGGCGCGGTCGGTAAAGTTGTTCCATTTTTTGAAGCGAAGGTTGTGGATCTGGATACCGGGAAAACGCTGGGCGTTAATCAAAGAGGCGAACTGTGTGTGAGAGGTCCTATGATTATGTCCGGTTATGTAAACAATCCGGAAGCGACCAACGCCTTGATTGACAAGGATGGATGGCTACATTCTGGAGACATAGCTTACTGGGACGAAGACGAACACTTCTTCATCGTTGACCGCCTGAAGTCTCTGATTAAGTACAAAGGCTATCAGGTGGCTCCCGCTGAATTGGAATCCATCTTGCTCCAACACCCCAACATCTTCGACGCAGGTGTCGCAGGTCTTCCCGACGATGACGCCGGTGAACTTCCCGCCGCCGTTGTTGTTTTGGAGCACGGAAAGACGATGACGGAAAAAGAGATCGTGGATTACGTCGCCAGTCAAGTAACAACCGCGAAAAAGTTGCGCGGAGGAGTTGTGTTTGTGGACGAAGTACCGAAAGGTCTTACCGGAAAACTCGACGCAAGAAAAATCAGAGAGATCCTCATAAAGGCCAAGAAGGGCGGAAAGATCGCCGTGTAA',targetArr:'GTGAAAA,CTGAGAC,CTGAGGA,CTGAGTA,CTGAATA,GCGAAAA,CCGACGA'}}>
        <Form.Item
          name="str"
          label="全序列"
          rules={[
            {
              required: true,
              message: '宝宝输入全序列',
            },
          ]}
          
        >
          <TextArea rows={4} placeholder="输入密码子" maxLength={100000} />
        </Form.Item>
        <Form.Item
          name="targetArr"
          label="找序列"
          rules={[
            {
              required: true,
              message: '宝宝输入需要查找的序列',
            },
          ]}
          >
          <TextArea rows={4} placeholder="输入需要查找的子序列" maxLength={100000} />
        </Form.Item>
        <Form.Item >
          <Button type="primary" onClick={onCheck}>
            宝宝点击
          </Button>
      </Form.Item>
      </Form>


    </div>
    <div style={{}}>
    {
      clipStr.map((item,index) =>{
        return<span>
          {item + '     ' }
          {clipIdxArr[index]}
          {clipIdxArr[index] && <Select key={clipIdxArr[index]} style={{width:'200px'}} onChange={handleChange}>
            {dealData[clipIdxArr[index]] && dealData[clipIdxArr[index]].arr.map(item2 =>  {
              return  <Option key={clipIdxArr[index] + ' ' + item2 } value={item2}>{item2}</Option>
            })}
          </Select>}
          <span style={{background:'#1890ff',color:'#fff'}}>{dealData[clipIdxArr[index]] && dealData[clipIdxArr[index]].origin}</span>
          {'     '}
        </span> 
      })
    }
    <Button type="primary" onClick={makeList}>宝宝再点击 生成序列</Button>
    </div>
    <TextArea disabled value={finalShow}></TextArea>
  </>
}


export default ZhangPages